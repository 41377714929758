<template>
  <div class="depositDetail">
    <div class="top">
      <i class="el-icon-back" @click="back()"></i>
      <span>{{ $t('m.'+detailName) }}</span>
    </div>

    <template v-if="detailName == '团队保证金明细'">
      <div class="cont">
        <div class="cont-warp">

          <div class="sel">
            <span>{{$t('m.筛选类型')}}</span>
            <el-select v-model="operation" :placeholder="$t('m.请选择')" @change="operationChange()">
              <el-option v-for="item in options" 
              :key="item.value" 
              :label="$t('m.'+item.label)" 
              :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="tabl">
            <el-table :data="capitalDetailedList" style="width: 100%">
              <el-table-column prop="createTime" :label="$t('m.时间')" width="300">
                <template slot-scope="scope">{{ moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")}}</template>
              </el-table-column>
              <el-table-column prop="address" :label="$t('m.保证金')" width="200">
                <template slot-scope="scope">
                  <span>{{
                    (scope.row.type == "income" ? " + " : " - ") +
                    scope.row.amount +
                    " " +
                    scope.row.coinType +
                    " "
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="name" :label="$t('m.类型')" width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row.operation == 1">{{$t('m.补充保证金')}}</span>
                  <span v-if="scope.row.operation == 11">{{$t('m.提取保证金')}}</span>
                  <span v-if="scope.row.operation == 12">{{$t('m.转入至项目')}}</span>
                  <span v-if="scope.row.operation == 2">{{$t('m.项目转出')}}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="name"
                :label="$t('m.备注')"
                align="right"
                width="500"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.coinType != 'VTN' && scope.row.mark">
                    <span v-if="JSON.parse(scope.row.mark).VTN">{{ Number(JSON.parse(scope.row.mark).VTN) + " VTN "}}</span>
                    <span>{{$t('m.标记汇率') + "  " + JSON.parse(scope.row.mark).rate}}</span>

                    <span v-if="JSON.parse(scope.row.mark).convertUsdt">{{
                    $t('m.折合') + " " +
                    Number(JSON.parse(scope.row.mark)
                    .convertUsdt.toString()
                    .slice(0, JSON.parse(scope.row.mark)
                    .convertUsdt.toString()
                    .indexOf(".") + 5)) + " USDT" }}</span>

                    <span v-if="JSON.parse(scope.row.mark).convertVtn">{{
                      $t('m.折合') + " " +
                      Number(
                        JSON.parse(scope.row.mark)
                        .convertVtn.toString()
                        .slice(0, JSON.parse(scope.row.mark)
                        .convertVtn.toString()
                        .indexOf(".") + 5)) + " VTN"}}</span>
                    <span>{{scope.row.type == "income" ? $t('m.转出') : $t('m.转入')}}</span>
                  </span>
                  <span v-else>- -</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="cont">
        <div class="cont-warp">
          <div class="sel">
            <span>{{$t('m.筛选类型')}}</span>
            <el-select
              v-model="operation"
              :placeholder="$t('m.请选择')"
              @change="operationChange()">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="$t('m.'+item.label)" 
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="tabl">
            <el-table :data="capitalDetailedList" style="width: 100%">
              <el-table-column prop="createTime" :label="$t('m.时间')" width="300">
                <template slot-scope="scope">{{
                  moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                }}</template>
              </el-table-column>
              <el-table-column prop="address" :label="$t('m.保证金(VTN)')" width="400">
                <template slot-scope="scope">{{
                  (Number(scope.row.amount) == 0
                    ? ""
                    : scope.row.type == "income"
                    ? " + "
                    : " - ") + Number(scope.row.amount)
                }}</template>
              </el-table-column>
              <el-table-column prop="name" :label="$t('m.类型')" width="300">
                <template slot-scope="scope">
                  <span v-if="scope.row.operation == 1">{{$t('m.转入')}}</span>
                  <span v-if="scope.row.operation == 11">{{$t('m.转出')}}</span>
                  <span v-if="scope.row.operation == 12">{{$t('m.亏损赔付')}}</span>
                  <span v-if="scope.row.operation == 2">{{$t('m.盈利分成')}}</span>
                  <span v-if="scope.row.operation == 13">{{$t('m.资金管理费')}}</span>
                  <span v-if="scope.row.operation == 14">{{$t('m.收益分成费')}}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="name"
                :label="$t('m.备注')"
                align="right"
                width="200">

                <template slot-scope="scope">
                  <span style="color:red">{{scope.row.remark?(' [ '+scope.row.remark+' ] '):''}}</span>
                  <span> </span>
                  <span>{{scope.row.publicCode?scope.row.publicCode:scope.row.remark?'':'--'}}</span>
                </template>

              </el-table-column>

            </el-table>
          </div>
        </div>
      </div>
    </template>

    <div class="pagination-warp">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100, 500]"
        :page-size="20"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { temaCapitalDetailed, settleDetail } from "../../request/api";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      moment,
      options: [
        {
          value: null,
          label: '全部',
        },
        {
          value: "1",
          label: '补充保证金',
        },
        {
          value: "11",
          label: '提取保证金',
        },
        {
          value: "12",
          label: '转入至项目',
        },
        {
          value: "2",
          label: '项目转出',
        },
      ],
      options2: [
        {
          value: null,
          label: '全部',
        },
        {
          value: "1",
          label: '转入',
        },
        {
          value: "11",
          label: '转出',
        },
        {
          value: "12",
          label: '亏损赔付',
        },
        {
          value: "2",
          label: '盈利分成',
        },
        {
          value: "13",
          label: '资金管理费',
        },
        {
          value: "14",
          label: '收益分成费',
        },
      ],

      detailName: '团队保证金明细',
      operation: null,

      capitalDetailedList: null,
      total: 0,

      defaultTeam: null,
      pageNo: 1,
      pageSize: 20,
      projectId: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    back() {
      sessionStorage.setItem("active", 3);
      this.$router.back(-1);
    },

    operationChange() {
      console.log(this.operation);
      this.pageNo = 1;
      this.pageSize = 20;
      if (this.detailName == '团队保证金明细') {
        this.temaCapitalDetailed();
      } else {
        this.settleDetail();
      }
    },

    //   查询团队明细
    temaCapitalDetailed() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        operation: this.operation,
      };
      temaCapitalDetailed(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let CapitalDetailedList = res.data.CapitalDetailedList;
          if (CapitalDetailedList) {
            CapitalDetailedList.forEach((e) => {
              e.amount = Number(e.amount.slice(0, e.amount.indexOf(".") + 5));
            });
          }
          this.capitalDetailedList = CapitalDetailedList;
          this.total = res.data.total;
          console.log(JSON.parse(this.capitalDetailedList[0].mark).rate);
        }
      });
    },

    // 查询项目明细
    settleDetail() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.projectId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        operation: this.operation,
      };
      settleDetail(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.capitalDetailedList = res.data.detailList;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.detailName == '团队保证金明细') {
        this.temaCapitalDetailed();
      } else {
        this.settleDetail();
      }
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      if (this.detailName == '团队保证金明细') {
        this.temaCapitalDetailed();
      } else {
        this.settleDetail();
      }
    },
  },
  created() {
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
  },
  mounted() {
    let type = this.$route.query.type;
    if (type) {
      let id = this.$route.query.id;
      this.projectId = id;
      this.detailName = this.$t('m.项目保证金明细');
      this.settleDetail();
    } else {
      this.temaCapitalDetailed();
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.depositDetail {
  .top {
    display: flex;
    align-items: center;
    height: 64px;
    color: #102859;
    box-shadow: 0px 1px 6px 0px rgba(19, 57, 119, 0.06);
    padding-left: 50px;
    i {
      cursor: pointer;
    }
    span {
      margin-left: 20px;
    }
  }
  .cont {
    display: flex;
    justify-content: center;
    .cont-warp {
      width: 1200px;
      .sel {
        margin: 20px 0;
        font-size: 12px;
        color: #55698a;
        .el-select {
          margin-left: 10px;
        }
      }
    }
  }
  .pagination-warp {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}
</style>