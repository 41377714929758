<template>
  <div class="box-nav">
    <template v-if="templateShow">
      <div class="navs" v-if="templateList">
        <div class="navStyle" style="padding: 0">

          <div class="navTop-left">
            <div>
              <i
                v-if="
                  templateList
                    ? templateList.length > 10 && listDataNum != 0
                    : ''"
                @click="templateLeftBtn"
                class="el-icon-arrow-left"
                style="margin-right: 10px; cursor: pointer"></i>
            </div>

            <div
              v-for="(item, index) in listData"
              :key="index"
              class="navTopItem"
              :class="index == listIndex ? 'ItemStyle' : ''"
              @click="listBtn(index)">
              {{ item.templateName + " (" + item.total + ")" }}
            </div>
            <i
              @click="templateRightBtn"
              v-if="
                templateList
                  ? templateList.length > 10 && listData.length == 10
                  : ''"
              class="el-icon-arrow-right"
              style="cursor: pointer"></i>
          </div>
          <div class="navTop-right" @click="getTemplateSortList()">
            {{$t('m.模板排序')}}</div>
        </div>

        <div v-if="templateList" class="navStyle">
          <div class="navCenterLeft">
            <div>
              {{
                currentTemplate
                ? $t('m.'+currentTemplate.strategyName) 
                  + "-" 
                  + (currentTemplate.transactionType == "spot"
                      ? $t('m.币币')
                      : $t('m.合约'))
                : "--"
              }}
            </div>
            <div>
              {{ currentTemplate ? currentTemplate.exchangeName : "--" }}-{{
                currentTemplate
                ? currentTemplate.templatePattern == 0
                  ? transactionPair
                  : $t('m.多币对&(1)个').replace('&(1)', currentTemplate.symbolCount)
                : "--"
              }}
            </div>
            <div>
              {{$t('m.投入') + ' ' + currentTemplate ? currentTemplate.investCoin : "--" }}</div>

          </div>
          <div class="navCenterRight">
            <div @click="deleteStrategyTemplate()" style="color: #f56c6c">
              {{$t('m.移除模板')}}</div>
            <div @click="copyTransaction()">
              {{$t('m.复制交易')}}</div>
            <div @click="ModifyTemplate()">
              {{$t('m.总投入配置')}}</div>
            <div @click="updateTemplateNameShow = true">
              {{$t('m.重命名')}}</div>

            <div
              v-if="currentTemplate ? currentTemplate.transactionType == 'spot' : ''">
              <div>{{$t('m.自动交易')}}</div>
              <el-switch
                v-model="automatic"
                inactive-text=""
                @change="updateTemplateTradeSwitch">
              </el-switch>
            </div>
          </div>
        </div>

        <div v-if="templateList" class="navStyle">
          <div class="navCenterLeft">
            <div class="addBtn" @click="addAccount">
              <i class="el-icon-plus"></i>
              {{$t('m.添加用户')}}
            </div>
          </div>
          <div class="navCenterRight">
            <div
              v-if="currentTemplate ? currentTemplate.templatePattern == 1 : ''"
              @click="addCurrencyPair()">
              {{$t('m.增加子币对')}}</div>

            <div
              v-if="currentTemplate ? currentTemplate.templatePattern == 1 : ''"
              @click="detailPair()" >
              {{$t('m.删除子币对')}}</div>

            <div @click="batchUpdate()">
              {{$t('m.参数')}}</div>

            <span class="brdStyle"></span>
            <div @click="operationBtn('batchTermination')">
              {{$t('m.终止')}}</div>

            <div
              @click="operationBtn('suspend')"
              v-if="
                currentTemplate &&
                currentTemplate.transactionType == 'spot' &&
                executeShow">
              {{$t('m.暂停')}}</div>

            <div
              @click="operationBtn('restart')"
              v-if="
                currentTemplate &&
                currentTemplate.transactionType == 'spot' &&
                suspendDataShow">
              {{$t('m.重启')}}</div>

            <div
              @click="operationBtn('increaseShow')"
              v-if="
                currentTemplate && currentTemplate.transactionType == 'spot'
              ">
              {{$t('m.加仓')}}</div>

            <div
              @click="operationBtn('add')"
              v-if="
                currentTemplate && currentTemplate.transactionType != 'spot'
              ">
              {{$t('m.加仓')}}</div>

            <div
              @click="operationBtn('subtract')"
              v-if="
                currentTemplate && currentTemplate.transactionType != 'spot'
              ">
              {{$t('m.减仓')}}</div>
          </div>
        </div>

        <SearchBox
          v-if="templateList"
          class="searchStyle"
          @btnSubmit="btnSubmit"
          @btnBack="btnBack"
          :searchData="searchData"
        />

        <DetailsData
          v-if="templateList"
          @selectRow="selectRow"
          :modify="modify"
          :data="tableData"
          :details="details"
          detailBtn="true"
          :rowStyleData="rowStyleData"
          :label="orderData"
          @modifyBtn="modifyBtn"
          @getTemplateMessage="getTemplateMessage"
          class="detailsData"
        />

        <div class="paginStyle">
          <div></div>
          <div v-if="listNumber >= 10" class="paginCenter">
            <span>{{$t('m.共计订单&(1)条').replace('&(1)', listNumber)}}</span>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pageNo"
              :page-size="10"
              background
              layout="prev, pager, next, jumper"
              :total="listNumber">
            </el-pagination>
          </div>
          <div>{{$t('m.已选择订单&(1)条').replace('&(1)', selectList ? selectList.length : 0)}}</div>
        </div>
      </div>

      <div class="content" v-if="templateList">
        <div v-if="!showIcon" class="bottom">
          <span class="bottom-text">
            {{$t('m.操作日志')}}</span>
          <div @click="openLog" class="bottom-icon">
            <img
              class="bottom-img"
              src="../../assets/icon/more_top.png"
              alt=""
            />
          </div>
          <div class="more-btn" @click="toLogDetail">
            {{$t('m.更多')}}</div>
        </div>
        <div class="selectBtn" v-if="showIcon">
          <div class="bottom2">
            <span class="bottom-text">
              {{$t('m.操作日志')}}</span>
            <div @click="backLog" class="bottom-icon">
              <img
                class="bottom-img"
                src="../../assets/icon/more_bottom.png"
                alt=""/>
            </div>
            <div class="more-btn" @click="toLogDetail">
              {{$t('m.更多')}}</div>
          </div>
          <div class="navSelect">
            <div class="navStyle" v-if="logList.length == 0">
              {{$t('m.暂无记录')}}</div>
            <div
              v-else
              class="navStyle"
              v-for="(item, index) in logList"
              :key="index">
              <div>{{ item.createTime }}</div>
              <div>[{{ item.templateName }}]</div>
              <div>[{{ item.publicCode }}]</div>
              <div>[{{ $t('m.'+item.strategyName) }}]</div>
              <div>[{{ item.content }}]</div>
              <div v-if="item.msg">[{{ item.msg }}]</div>
              <div v-if="!item.msg">[{{$t('m.操作成功')}}]</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 缺省页 -->
      <div class="defaultPage" v-if="!templateList">
        <img class="img1" src="../../assets/image/img_002@2x.png" alt="" />
        <span class="create-template" @click="createPolicyTemplate()">
          {{$t('m.策略模板还是空的，快去创建策略模板吧～')}}</span>
        <img class="img2" src="../../assets/image/img_001@2x.png" alt="" />
      </div>
    </template>

    <!-- 终止 -->
    <el-dialog
      :title="$t('m.终止')"
      :visible.sync="terminationShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="termination">
        <div class="title">
          {{$t('m.终止确认')}}</div>

        <div class="text">
          {{$t('m.请确认终止现有策略实例&(1)个，确认终止后，策略将自动撤销未成交的委托单，并兑换成投入币种。')
          .replace('&(1)', selectList.length)}}</div>

        <div class="tips">
          {{$t('m.此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。')}}</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="terminationShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="batchStrategyEnd">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 暂停 -->
    <el-dialog
      :title="$t('m.暂停')"
      :visible.sync="suspendShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="suspend">
        <div class="title">
          {{$t('m.暂停确认')}}</div>
        <div class="text">
          {{$t('m.请确认暂停现有策略实例&(1)个，确认暂停后，策略将自动挂起，不再执行新的委托，未成交的委托单继续等待成交，数据不再更新。请确认终止现有策略实例')
          .replace('&(1)', selectList.length)}}</div>

        <div class="tips">
          {{$t('m.此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。')}}</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="suspendShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="confirmSuspend">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 现货加仓 -->
    <el-dialog
      :title="$t('m.加仓')"
      :visible.sync="increaseShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="increase" v-if="limitMap">

        <div class="text">
          <el-tabs v-model="increaseActive" @tab-click="handleClick">
            <el-tab-pane :label="$t('m.可用资金比例')" name="1">

              <div class="box">
                <span class="box-title">
                  {{$t('m.可用资金百分比')}}</span>
                <el-input
                  v-model="proportion"
                  :placeholder="$t('m.请输入内容')"
                  type="number">
                  <template slot="append">%</template>
                </el-input>
              </div>

              <div class="box">
                <span class="box-title">
                  {{$t('m.加仓投入上限')}}</span>
                <el-input v-model="max" :placeholder="$t('m.请输入内容')" type="number">
                  <template slot="append">{{ investCoin }}</template>
                </el-input>
              </div>

              <div class="box">
                <span class="box-title">
                  {{$t('m.加仓投入下限')}}</span>
                <el-input v-model="min" :placeholder="$t('m.请输入内容')" type="number">
                  <template slot="append">{{ investCoin }}</template>
                </el-input>
              </div>
            </el-tab-pane>

            <el-tab-pane :label="$t('m.固定投入金额')" name="2">
              <div class="box">
                <span class="box-title">{{$t('m.加仓金额')}}</span>
                <el-input
                  v-model="fixedInvestment"
                  :placeholder="$t('m.请输入内容')"
                  type="number">
                  <template slot="append">{{ investCoin }}</template>
                </el-input>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <div class="tips">
          <div>{{$t('m.满足比例，但低于下限不投；')}}</div>
          <div>{{$t('m.满足比例，但高于上限的按上限投；')}}</div>
          <div>{{$t('m.比例投入或固定投入需满足策略本身投入限制，')}}</div>
          <div>{{$t('m.高于策略限制上限按策略上限投。')}}</div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="increaseShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="scaleIn">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 合约加减仓 -->
    <el-dialog
      :title="contrIncreaseType == 'add' ? $t('m.加仓') : $t('m.减仓')"
      :visible.sync="contrIncreaseShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="increase">
        <div class="text">
          <div class="box">
            <span class="box-title">
              {{contrIncreaseType == "add" ? $t('m.加仓金额') : $t('m.减仓金额')}}</span>

            <el-input
              v-model="fixedInvestment"
              :placeholder="$t('m.请输入内容')"
              type="number">
              <i class="iconStyle" slot="suffix">{{ investCoin }}</i>
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contrIncreaseCancel()">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="contrIncreaseConfirm()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 增加币对 -->
    <template v-if="addCurrencyPairShow">
      <el-dialog
        :title="$t('m.增加子币对')"
        :visible.sync="addCurrencyPairShow"
        width="20%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="addCurrencyPair">
          <div class="box">
            <div
              class="checkbox-warp"
              v-for="(item, idx) in pairList"
              :key="idx">
              <el-checkbox :checked="item.type" @change="pairChange(item)">
                <div class="box-text">
                  <span>{{ item.counterCoin }}</span>
                  <span>{{ "/" + item.baseCoin }}</span>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCurrencyPairShow = false">
            {{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="confirmAddCurrencyPair()">
            {{$t('m.确 定')}}</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 删除子币对 -->
    <template v-if="detailPairShow">
      <el-dialog
        :title="t('m.删除子币对')"
        :visible.sync="detailPairShow"
        width="20%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="addCurrencyPair">
          <div class="box">
            <div
              class="checkbox-warp"
              v-for="(item, idx) in detailPairList"
              :key="idx">
              <el-checkbox :checked="item.type" @change="pairChange(item)">
                <div class="box-text">
                  <span>{{ item.counterCoin }}</span>
                  <span>{{ "/" + item.baseCoin }}</span>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailPairShow = false">
            {{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="confirmDetailPair()">
            {{$t('m.确 定')}}</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 模板重命名 -->
    <el-dialog
      :title="$t('m.模板重命名')"
      :visible.sync="updateTemplateNameShow"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <el-input
        v-model="templateNameCopy"
        type="text"
        :placeholder="$t('m.请输入内容')">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="updateTemplateNameShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="modifyName">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改模板总投入 -->
    <el-dialog
      :title="$t('m.修改模板投入配置')"
      :visible.sync="ModifyTemplateInvestmentShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="increase" v-if="limitMap && investConfig">
        <div class="title">
          <span>{{$t('m.配置投入')}}</span>
          <span>{{
            $t('m.范围') +
            calculation(
              tradePairs.investCoin,
              tradePairs,
              limitMap.minInvest &&
                (limitMap.minInvest != "" || limitMap.maxInvest != "")
                ? limitMap.minInvest
                : item.start
            ) +
            "~" +
            calculation(
              tradePairs.investCoin,
              tradePairs,
              limitMap.maxInvest &&
                (limitMap.minInvest != 0 || limitMap.maxInvest != 0)
                ? limitMap.maxInvest
                : item.end
            ) +
            " " +
            investCoin
          }}</span>
        </div>
        <div class="text">
          <el-tabs v-model="templateActive">
            <el-tab-pane label="可用资金比例" name="1">
              <div class="box">
                <span class="box-title">
                  {{$t('m.可用资金百分比')}}</span>
                <el-input
                  v-model="investConfig.proportionInvest.proportion"
                  :placeholder="$t('m.请输入内容')">
                  <template slot="append">%</template>
                </el-input>
              </div>

              <div class="box">
                <span class="box-title">
                  {{$t('m.投入上限')}}</span>
                <el-input
                  v-model="investConfig.proportionInvest.maxInvest"
                  :placeholder="$t('m.请输入内容')">
                  <template slot="append">{{ investCoin }}</template>
                </el-input>
              </div>
              
              <div class="box">
                <span class="box-title">
                  {{$t('m.投入下限')}}</span>
                <el-input
                  v-model="investConfig.proportionInvest.minInvest"
                  :placeholder="$t('m.请输入内容')">
                  <template slot="append">{{ investCoin }}</template>
                </el-input>
              </div>
            </el-tab-pane>

            <el-tab-pane label="固定投入金额" name="2">
              <div class="box">
                <span class="box-title">
                  {{$t('m.定投金额')}}</span>
                <el-input
                  v-model="investConfig.fixInvest"
                  :placeholder="$t('m.请输入内容')">
                  <template slot="append">{{ investCoin }}</template>
                </el-input>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <div class="tips">
          <div>{{$t('m.修改模版总投入不影响已投入的策略')}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ModifyTemplateInvestmentShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="confirmModifyTemplate()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 模板排序列表 -->
    <el-dialog
      :title="$t('m.模板排序')"
      :visible.sync="dragSort"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="templateSort">
        <p class="title">
          {{$t('m.新分配的用户将依据排序依次启动符合模板要求且开启自动交易的策略')}}</p>

        <div class="templateSort-content">
          <div class="content-title">
            <span>{{$t('m.模板名称')}}</span>
            <span>{{$t('m.自动交易')}}</span>
            <span>{{$t('m.排序')}}</span>
          </div>

          <ul>
            <li
              v-for="(item, index) in sortTemplateList"
              :key="index"
              v-dragging="{
                list: sortTemplateList,
                item: item,
                group: 'templateId',
              }">
              <div class="content-box">
                <span>{{ item.templateName }}</span>
                <el-switch v-model="item.tradeSwitch" active-color="#007AFF">
                </el-switch>
                <img src="../../assets/icon/sort.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dragSort = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="sortConfirm()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改参数 -->
    <template v-if="modifyParametersShow">
      <el-dialog
        :title="$t('m.修改参数')"
        :visible.sync="modifyParametersShow"
        width="40%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="modifyParameters">
          <p class="otherSetting-title">
            {{$t('m.参数设置')}}</p>
          <!-- 其他策略 -->
          <template
            v-if="
              strategyId != 'WL20210624182847743' &&
              strategyId != 'WL20210512112808681' &&
              strategyId != 'WL20190402171936974x' &&
              strategyId != 'WL202007311426363425x' &&
              strategyId != 'WL202007311426363424x' &&
              strategyId != 'WL202X0512112808681'
            ">
            <template v-for="(item, idx) in reserves">
              <div :key="'reserves1'+idx"
                class="parameter-box"
                v-if="item.visiable == '1' || strategyId == 'CL20181115155643599x'">

                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>

                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"
                    v-if="
                      item.name != '单格投入' &&
                      strategyId == 'CL20181115155003478x'
                    "/>

                  <span
                    style="margin-left: 6px"
                    v-if="
                      item.name == '单格投入' &&
                      strategyId == 'CL20181115155003478x'"></span>

                  <!-- 不计算范围 -->
                  <span
                    v-if="
                      ((item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')) &&
                      item.name != '价格上限' &&
                      item.name != '价格下限'">
                    {{
                      $t('m.范围') +
                      " " +
                      (item.valueMin &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) +
                      "~" +
                      (item.valueMax &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                    }}</span>

                  <!-- 计算范围 -->
                  <span
                    v-if="
                      item.valueMin != '' &&
                      item.valueMax != '' &&
                      item.valueMin != '0' &&
                      item.valueMax != '0' &&
                      item.name != '价格上限' &&
                      item.name != '价格下限'
                    ">
                    {{
                      $t('m.范围') +
                      calculation(
                        tradePairs.investCoin,
                        tradePairs,
                        item.valueMin &&
                          (item.valueMin != "" || item.valueMax != "")
                          ? item.valueMin
                          : item.start
                      ) +
                      "~" +
                      calculation(
                        tradePairs.investCoin,
                        tradePairs,
                        item.valueMax &&
                          (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end
                      )
                    }}</span>
                </div>

                <div class="right">
                  <el-input
                    :placeholder="$t('m.请输入内容')"
                    v-model="item.value"
                    type="number"
                    @blur="parameterVerification($event, item)">
                    
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="
                        item.valueMin != 0 ||
                        item.valueMax != 0 ||
                        item.name == '价格上限' ||
                        item.name == '价格下限'
                      ">
                      {{ tradePairs.investCoin }}</i>
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="item.valueType == 'percent'">%</i>
                  </el-input>
                </div>
              </div>
            </template>
          </template>

          <!-- 恒量定投 -->
          <template v-if="strategyId == 'WL20190402171936974x'">
            <template v-for="(item, idx) in reserves">
              <!-- 定投周期 -->
              <div :key="'reserves2'+idx" class="parameter-box" v-if="item.name == '定投周期'">
                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>
                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"
                  />
                </div>
                <div class="right">
                  <el-cascader
                    v-model="fixedTime"
                    :options="times"
                    @change="handleChange">
                  </el-cascader>
                </div>
              </div>

              <!-- 单笔金额 -->
              <div :key="'reserves2'+idx" class="parameter-box" v-if="item.name == '单笔金额'">
                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>
                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"
                  />
                  <span v-if="item.valueMin || item.start">{{
                    $t('m.范围') +
                    " " +
                    (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                      ? item.valueMin
                      : item.start) +
                    "~" +
                    (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                      ? item.valueMax
                      : item.end)
                  }}</span>
                </div>
                <div class="right">
                  <el-input
                    :placeholder="$t('m.请输入内容')"
                    type="number"
                    v-model="item.value"
                    @blur="parameterVerification($event, item)">
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="item.valueMin != 0 || item.valueMax != 0"
                      >{{ tradePairs.investCoin }}</i>
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="item.valueType == 'percent'">%</i>
                  </el-input>
                </div>
              </div>
            </template>
          </template>

          <!-- 海风马丁  WL20210512112808681 -->
          <template
            v-if="
              strategyId == 'WL20210512112808681' ||
              strategyId == 'WL202X0512112808681'
            ">
            <template v-for="(item, idx) in reserves">
              <!-- 补仓金额模式 -->
              <template>
                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="item.visiable == '1' && item.name == '补仓金额模式'">
                  <div class="special-box">
                    <span
                      class="special-box-title">
                      {{ item.name }}</span>
                      
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishment }"
                        @click="replenishment = true">
                        {{$t('m.倍数补仓')}}</span>
                      <span
                        :class="{ on: !replenishment }"
                        @click="replenishment = false">
                        {{$t('m.增量补仓')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="
                    (replenishment &&
                      (item.name == '补仓金额倍数' ||
                        item.name == '倍投起始单' ||
                        item.name == '最大做单数')) ||
                    (!replenishment &&
                      (item.name == '补仓金额增量' ||
                        item.name == '最大做单数'))">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                    <span v-if="item.valueMin || item.start">{{
                      $t('m.范围') +
                      " " +
                      (item.valueMin &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) +
                      "~" +
                      (item.valueMax &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                    }}</span>
                  </div>

                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      type="number"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 补仓间隔模式 -->
              <template>
                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="item.visiable == '1' && item.name == '补仓间隔模式'">
                  
                  <div class="special-box">
                    <span
                      class="special-box-title">
                      {{ item.name }}</span>
                      
                    <div class="special-box-cont">
                      <span
                        :class="{ on: intervalMode }"
                        @click="intervalMode = true">
                        {{$t('m.固定间隔')}}</span>
                        
                      <span
                        :class="{ on: !intervalMode }"
                        @click="intervalMode = false">
                        {{$t('m.自定义间隔')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="
                    (intervalMode &&
                      (item.id == 'reserve_38' || item.id == 'reserve_39')) ||
                    (!intervalMode &&
                      (item.id == 'reserve_40' ||
                        item.name.search('replenishment') != -1 ||
                        item.name.search('次补仓比例') != -1))">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                    <span v-if="item.valueMin || item.start">{{
                      $t('m.范围') +
                      " " +
                      (item.valueMin &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) +
                      "~" +
                      (item.valueMax &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                    }}</span>
                  </div>

                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      type="number"
                      @blur="parameterVerification($event, item)">
    
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'" >%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 其他参数 -->
              <template>
                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="
                    item.id != 'reserve_38' &&
                    item.id != 'reserve_37' &&
                    item.id != 'reserve_39' &&
                    item.id != 'reserve_7' &&
                    item.id != 'reserve_6' &&
                    item.id != 'reserve_9' &&
                    item.id != 'reserve_10' &&
                    item.id != 'reserve_40' &&
                    item.id != 'reserve_35' &&
                    item.id != 'reserve_52' &&
                    item.id != 'reserve_53' &&
                    item.id != 'reserve_54' &&
                    item.name.search('replenishment') == -1 &&
                    item.name.search('次补仓比例') == -1
                  ">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                    <span
                      v-if="
                        (item.valueMin || item.start) &&
                        item.name != '价格上限' &&
                        item.name != '价格下限'">{{
                        $t('m.范围') +
                        " " +
                        (item.valueMin &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) +
                        "~" +
                        (item.valueMax &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span>
                  </div>

                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      type="number"
                      @blur="parameterVerification($event, item)">

                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 信号参数 -->
              <template v-if="strategyId == 'WL202X0512112808681'">
                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="
                    item.id == 'reserve_52' ||
                    item.id == 'reserve_53' ||
                    item.id == 'reserve_54'
                  ">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                  </div>
                  <div class="right">
                    <div>
                      <el-select
                        v-model="item.signalName"
                        placeholder="请选择"
                        @change="signalChange($event, item)">
                        <el-option
                          v-for="(val, idx) in signalList"
                          :key="idx"
                          :label="val.signalName"
                          :value="val">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="signal-params">
                      <div class="one">
                        <el-select
                          v-model="item.paramBodyLabel"
                          placeholder="请选择"
                          @change="paramBodyValue($event, item)">
                          <template v-if="item.paramBody">
                            <el-option
                              v-for="(e, idx) in item.paramBody"
                              :key="idx"
                              :label="e.label"
                              :value="e">
                            </el-option>
                          </template>
                        </el-select>
                      </div>

                      <div class="two">
                        <el-select
                          v-model="item.ops"
                          placeholder="请选择"
                          @change="opsChange($event, item)">
                          <el-option
                            v-for="(ops, idx) in SymbolList"
                            :key="idx"
                            :label="ops.value"
                            :value="ops.value">
                          </el-option>
                        </el-select>
                      </div>

                      <div class="three">
                        <el-input
                          v-model="item.signalValue"
                          placeholder="阈值"
                          @change="signalValueChange($event, item)">
                        </el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>

          <!-- 合约策略 -->
          <template
            v-if="
              strategyId == 'WL202007311426363425x' ||
              strategyId == 'WL202007311426363424x' ||
              strategyId == 'WL20210624182847743'
            ">
            <template v-for="(item, idx) in reserves">
              <!-- 合约网格 等比/等差 -->
              <template
                v-if="
                  (gridDistribution && item.name == '网格间隔(等比)') ||
                  (!gridDistribution && item.name == '网格间隔(等差)')">

                <div :key="'reserves4'+idx" class="parameter-box">
                  
                  <div class="special-box">
                    <span class="special-box-title">
                      {{$t('m.网格分布')}}</span>
                      
                    <div class="special-box-cont">
                      <span
                        :class="{ on: gridDistribution }"
                        @click="gridDistribution = true">
                        {{$t('m.等比网格')}}</span>

                      <span
                        :class="{ on: !gridDistribution }"
                        @click="gridDistribution = false">
                        {{$t('m.等差网格')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves4'+idx" class="parameter-box" v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">
                      <span>{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                    </span>

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')
                      ">{{
                        $t('m.范围') +
                        " " +
                        (item.valueMin &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) +
                        "~" +
                        (item.valueMax &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span>

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'
                      ">{{
                        $t('m.范围') +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin &&
                            (item.valueMin != "" || item.valueMax != "")
                            ? item.valueMin
                            : item.start
                        ) +
                        "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax &&
                            (item.valueMin != 0 || item.valueMax != 0)
                            ? item.valueMax
                            : item.end
                        )
                      }}</span>
                  </div>
                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <template
                v-if="
                  item.name != '价格上限' &&
                  item.name != '价格下限' &&
                  item.name != '最大做多单数' &&
                  item.name != '最大做空单数' &&
                  item.name != '网格间隔(等差)' &&
                  item.name != '网格间隔(等比)' &&
                  item.name !== '补仓金额模式' &&
                  item.name !== '补仓下单模式' &&
                  item.name !== '止盈平仓模式' &&
                  item.name !== '止盈下单模式'
                ">
                <div :key="'reserves4'+idx" class="parameter-box" v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">
                      <span>{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                    </span>

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')
                      ">{{
                       $t('m.范围') +
                        " " +
                        (item.valueMin &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) +
                        "~" +
                        (item.valueMax &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span>

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'
                      ">{{
                        $t('m.范围') +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin &&
                            (item.valueMin != "" || item.valueMax != "")
                            ? item.valueMin
                            : item.start
                        ) +
                        "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax &&
                            (item.valueMin != 0 || item.valueMax != 0)
                            ? item.valueMax
                            : item.end
                        )
                      }}</span>
                  </div>

                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0">
                        {{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <template
                v-if="
                  (item.name == '最大做多单数' && contractType == 1) ||
                  (item.name == '最大做空单数' && contractType == 0) ||
                  (contractType == 2 &&
                    (item.name == '最大做空单数' ||
                      item.name == '最大做多单数'))">
                <div :key="'reserves4'+idx" class="parameter-box" v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">
                      <span>{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                    </span>

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')
                      ">{{
                        $t('m.范围') +
                        " " +
                        (item.valueMin &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) +
                        "~" +
                        (item.valueMax &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span>

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'
                      ">{{
                        $t('m.范围') +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin &&
                            (item.valueMin != "" || item.valueMax != "")
                            ? item.valueMin
                            : item.start
                        ) +
                        "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax &&
                            (item.valueMin != 0 || item.valueMax != 0)
                            ? item.valueMax
                            : item.end
                        )
                      }}</span>
                  </div>

                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <template
                v-if="item.name == '价格上限' || item.name == '价格下限'">
                <div :key="'reserves4'+idx" class="parameter-box">
                  <div class="left">
                    <span class="reserves-name">
                      <span>{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                    </span>
                  </div>
                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i class="iconStyle" slot="suffix">
                        {{$t('m.选填')}}</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- SMT合约 -->
              <template v-if="strategyId == 'WL20210624182847743'">
                <div :key="'reserves4'+idx"
                  class="parameter-box-smt"
                  v-if="item.name == '补仓金额模式'">

                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionOne }"
                        @click="replenishmentbutionSelect(true, '倍数补仓')">
                        {{$t('m.倍数补仓')}}</span>
                      <span
                        :class="{ on: !replenishmentbutionOne }"
                        @click="replenishmentbutionSelect(false, '增量补仓')">
                        {{$t('m.增量补仓')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves4'+idx"
                  class="parameter-box-smt"
                  v-if="item.name == '补仓下单模式'">
                  
                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionTwo }"
                        @click="replenishmentbutionSelect(true, '追踪补仓')">
                        {{$t('m.追踪补仓')}}</span>
                      <span
                        :class="{ on: !replenishmentbutionTwo }"
                        @click="replenishmentbutionSelect(false, '预埋单补仓')">
                        {{$t('m.预埋单补仓')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves4'+idx"
                  class="parameter-box-smt"
                  v-if="item.name == '止盈平仓模式'">

                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionThree }"
                        @click="replenishmentbutionSelect(true, '一次性止盈')">
                        {{$t('m.一次性止盈')}}</span>
                      
                      <span
                        :class="{ on: !replenishmentbutionThree }"
                        @click="replenishmentbutionSelect(false, '逐单止盈')">
                        {{$t('m.逐单止盈')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves4'+idx"
                  class="parameter-box-smt"
                  v-if="item.name == '止盈下单模式'">
                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionFour }"
                        @click="replenishmentbutionSelect(true, '追踪止盈')">
                        {{$t('m.追踪止盈')}}</span>
                      <span
                        :class="{ on: !replenishmentbutionFour }"
                        @click="replenishmentbutionSelect(false, '预埋单止盈')">
                        {{$t('m.预埋单止盈')}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </template>

            <!-- 开仓阈值 -->
            <div class="parameter-box">
              <div class="left">
                <span class="reserves-name">
                  <span>{{$t('m.策略开仓阈值')}}</span>
                </span>
              </div>
              <div class="right">
                <el-input
                  :placeholder="$t('m.请输入内容')"
                  v-model="openingThreshold"
                  @change="openingThresholdChange">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>
          </template>

          <p class="otherSetting-title">
            {{$t('m.其他设置')}}</p>

          <div class="otherSetting">
            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.总收益止盈')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="SP()"
                />
              </div>

              <div class="right">
                <el-input 
                  :placeholder="$t('m.选填')"
                  v-model="stopProfit"
                  type="number"
                  @blur="otherSettingCheck('止盈', stopProfit)">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>

            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.总亏损止损')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="SL()"
                />
              </div>
              <div class="right">
                <el-input
                  :placeholder="$t('m.选填')"
                  v-model="stopLoss"
                  type="number"
                  @blur="otherSettingCheck('止损', stopLoss)">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>

            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.定时自动终止')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="timeTips()"
                />
              </div>
              <div class="right">
                <el-date-picker
                  v-model="stopTime"
                  class="date-picker"
                  type="datetime"
                  :placeholder="$t('m.选填')"
                  :picker-options="pickerOptions"
                  :clearable="false">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <div class="btn" @click="confirmUpdateParam()">
            {{$t('m.修改参数')}}</div>
        </span>
      </el-dialog>
    </template>
  </div>
</template>




<script>
import DetailsData from "../../components/detailsData.vue";
import SearchBox from "../../components/searchBox.vue";

import moment, { duration } from "moment";
import { times } from "../../assets/js/time";
import {
  getTemplateList,
  getStrategyPageByTemplateList,
  updateTemplateName,
  updateTemplateTradeSwitch,
  deleteStrategyTemplate,
  strategyEnd,
  getOperateLog,
  batchSuspend,
  batchRestart,
  batchUpdateParam,
  batchStrategyEnd,
  getLimitConfig,
  batchAddStore,
  getPairPage,
  batchManyAddSymbol,
  updateTemplateInvestCoinnNum,
  getPairPageById,
  deletePair,
  getTemplateSortList,
  updateTemplateSort,
  updateParam,
  getTemplateMessage,
  contractUpdateParam,
  batchPositionAdjustment,
  querySignalList,
} from "../../request/api";
export default {
  components: { DetailsData, SearchBox },
  data() {
    return {
      listData: [],
      listIndex: 0,
      listDataNum: 0,
      modify: true,
      details: true,
      moment,
      tableData: [],
      //搜索数据
      searchTable: [],
      //表格样式
      rowStyleData: [
        {
          columnIndex: 5,
          style: [
            {
              state: "true5",
              style: "color:#E12525",
            },

            {
              state: "zero5",
              style: "color:#102859",
            },
            {
              state: "false5",
              style: "color:#26B34B",
            },
          ],
        },
        {
          columnIndex: 6,
          style: [
            {
              state: "true6",
              style: "color:#E12525",
            },

            {
              state: "zero6",
              style: "color:#102859",
            },
            {
              state: "false6",
              style: "color:#26B34B",
            },
          ],
        },
        {
          columnIndex: 7,
          style: [
            {
              state: "执行中",
              style: "color:#2174FF",
            },
            {
              state: "待触发",
              style: "color:#E12525",
            },
            {
              state: "已完成",
              style: "color:#102859",
            },
          ],
        },
      ],
      //搜索框内容
      searchData: [
        {
          name: "账户",
          type: "input",
        },
        {
          name: "实例状态",
          data: [
            this.$t('m.全部'), 
            this.$t('m.执行中'), 
            this.$t('m.终止中'), 
            this.$t('m.暂停中'), 
            this.$t('m.启动中'), 
            this.$t('m.启动错误')],
        },
        // {
        //   name: "总投入",
        //   type: "inputTwo",
        // },
      ],
      //表格内容
      orderData: [
        {
          title: "启动时间",
          nav: "startTime",
        },
        {
          title: "执行时长",
          nav: "executeTime",
        },
        {
          title: "实例编号",
          nav: "code",
        },
        {
          title: "账户",
          nav: "publicCode",
        },
        {
          title: "总投入",
          nav: "investCoinNum",
        },
        {
          title: "总收益",
          nav: "realEarnings",
        },
        {
          title: "总年化",
          nav: "realYearIncome",
        },
        {
          title: "状态",
          nav: "states",
        },
      ],
      automatic: null, //自动交易开关
      strategyType: "全部", //策略状态
      input: "",

      terminationShow: false, //终止
      suspendShow: false, //暂停
      increaseShow: false, //加仓
      increaseActive: "1", //加仓页
      templateActive: "1", //修改模板投入
      addCurrencyPairShow: false, //增加币对
      checked: false,
      project: null,
      templateList: null, //策略模板列表
      detailsList: [], // 模板详情
      strategyList: [],
      strategyListCopy: [],
      updateTemplateNameShow: false, //模板重命名显隐
      templateNameCopy: null,

      currentTemplate: null, //当前选中模板

      logList: [], //日志列表

      exchangeId: null,
      pairs: [],
      limitMap: null,
      fixedInvestment: null, //固定加仓
      proportion: null, //百分比
      max: null,
      min: null,
      pairList: [], //交易对列表
      ModifyTemplateInvestmentShow: false, //修该模板投入
      investConfig: null,

      detailsOperationAuthority: null, //操作权限
      userId: null,

      paramsType: true, //参数校验

      detailPairShow: false,
      detailPairList: null,

      dragSort: false,
      sortTemplateList: [], //排序模板列表
      total: 0,
      current: 1,
      pageNo: 1,
      pageSize: 10,
      logError: null,
      selectList: [], //获取选中的数据

      //   ******参数*****//
      selectStrategy: null,
      executeDetail: null,
      config: null,
      tradePairs: null,

      modifyParametersShow: false,
      reserves: [],
      reservesCopy: [],
      strategyId: null,
      strategyName: null,

      parameterCheck: true, //参数校验
      otherCheck: true,

      replenishment: true, //海风补仓模式
      intervalMode: true, //海风间隔模式

      // *********合约*************//
      contractTypeShow: false, //合约模式显示
      contractType: 1, //当前合约做多/空
      gridDistribution: true, //网格分布
      openingThreshold: null, //合约开仓阈值

      fixedTime: [], //恒量定投周期
      copyFixedTime: [], //备份
      times: times,

      stopProfit: null,
      stopLoss: null,
      stopTime: null,

      // ******自动终止时间日期禁用*****//
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime();
        },
      },

      isSingleUpdate: false, //是否单个修改参数
      listNumber: 0, //数据总数量
      showIcon: false, //日记

      contrIncreaseShow: false, //合约加仓
      contrIncreaseType: "add", //合约加仓/减仓

      transactionPair: null,

      executeShow: false, //执行中显隐
      suspendDataShow: false, //暂停中显隐
      investCoin: null, //投入币种

      transactionType: "spot",
      templateShow: false, //
      signalList: [], //信号列表
      SymbolList: [
        { value: "=" },
        { value: ">" },
        { value: "<" },
        { value: "≥" },
        { value: "≦" },
      ],
      parameterCheckList: [], //校验补充

      //SMT合约
      replenishmentbutionOne: true,
      replenishmentbutionTwo: true,
      replenishmentbutionThree: true,
      replenishmentbutionFour: true,
    };
  },
  methods: {
    //查询信号列表
    querySignalList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: JSON.parse(sessionStorage.getItem("defaultTeam")).teamId,
        isTotal: true,
        // this.project.teamProjectId
      };
      querySignalList(payload).then((res) => {
        if (res.status == 200) {
          console.log("信号列表", res.data.signalList);
          this.signalList = res.data.signalList;
          console.log(this.reserves);
        }
      });
    },
    // 信号选择
    signalChange(v, item) {
      console.log(v, item);
      item.signalName = v.signalName;
      item.signal = v;
      item.paramBody = JSON.parse(v.paramBody);
      item.paramBodyValue = item.paramBody[0].name;
      item.paramBodyLabel = item.paramBody[0].label;
      item.ops = "=";
      this.$forceUpdate();
    },

    // 信号参数选择
    paramBodyValue(v, item) {
      // item.paramBody = [v];
      item.paramBodyValue = v.name;
      item.paramBodyLabel = v.label;
      this.$forceUpdate();
    },

    // 符号选择
    opsChange(v, item) {
      item.ops = v;
      console.log(v, item);
      this.$forceUpdate();
    },
    // 信号阈值
    signalValueChange(v, item) {
      item.signalValue = v.trim();
      this.$forceUpdate();
      console.log(item);
    },
    // 参数解释文案
    reservesTips(v) {
      this.$alert("<div style='color:#102859'>" + v + "</div>", "", {
        confirmButtonText: this.$t('m.确定'),
        dangerouslyUseHTMLString: true,
      });
    },

    // 止盈
    SP() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止盈根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止盈后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.总收益止盈'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止盈根据总收益率计算，触发止盈后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.总收益止盈'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },
    // 止损
    SL() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止损根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止损后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.总收益止损'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止损根据总收益率计算，触发止损后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.总收益止损'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },

    // 定时终止
    timeTips() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。定时终止可能会有几分钟的偏差，触发后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.定时自动终止'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.定时终止可能会有几分钟的偏差，触发后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.定时自动终止'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },

    listBtn(e) {
      console.log(e);
      this.listIndex = e;
      this.currentTemplate = this.listData[e];
      this.currentTemplate.listIndex = e;
      this.currentTemplate.listDataNum = this.listDataNum;
      this.templateNameCopy = this.currentTemplate.templateName;
      this.investCoin = this.currentTemplate.investCoin;
      this.executeShow = false;
      this.suspendDataShow = false;
      this.orderData.forEach((e) => {
        if (e.title.indexOf("总投入") >= 0) {
          e.title = "总投入(" + this.currentTemplate.investCoin + ")";
        } else if (e.title.indexOf("总收益") >= 0) {
          e.title = "总收益(" + this.currentTemplate.investCoin + ")";
        }
      });
      sessionStorage.setItem(
        "currentTemplate",
        JSON.stringify(this.currentTemplate)
      );
      this.getOperateLog();
      this.automatic = this.listData[e].tradeSwitch == 1 ? true : false;
      this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
    },
    //右拉箭头
    templateRightBtn() {
      let listData = [];
      this.listDataNum++;
      this.listIndex = this.listIndex - this.listDataNum * 10;
      console.log(this.listIndex);
      if ((this.listDataNum + 1) * 10 < this.templateList.length) {
        for (
          let i = 10 * this.listDataNum;
          i < (this.listDataNum + 1) * 10;
          i++
        ) {
          listData.push(this.templateList[i]);
        }
      } else {
        for (let i = 10 * this.listDataNum; i < this.templateList.length; i++) {
          listData.push(this.templateList[i]);
        }
      }
      this.listData = listData;
    },
    //左拉箭头
    templateLeftBtn() {
      let listData = [];
      this.listDataNum--;
      this.listIndex += (this.listDataNum + 1) * 10;
      console.log(this.listIndex);
      for (
        let i = 10 * this.listDataNum;
        i < (this.listDataNum + 1) * 10;
        i++
      ) {
        listData.push(this.templateList[i]);
      }
      this.listData = listData;
    },
    modifyBtn() {
      console.log(1);
    },
    //获取选中数据
    selectRow(e) {
      this.selectList = e;
    },
    // 重置按钮
    btnBack() {
      this.searchTable = [];
      sessionStorage.removeItem("searchData");
      this.pageNo = 1;
      this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
    },
    // 查询按钮
    btnSubmit(data) {
      console.log(data);
      if (data[0] !== "" || data[1] !== this.$t('m.全部')) {
        this.searchTable = {
          states:
            data[1] == this.$t('m.执行中')
              ? "EXECUTE"
              : data[1] == this.$t('m.暂停中')
              ? "SUSPEND"
              : data[1] == this.$t('m.终止中')
              ? "STOPING"
              : data[1] == this.$t('m.启动中')
              ? "STARTING"
              : data[1] == this.$t('m.启动错误')
              ? "STARTERROR"
              : "",
          publicCode: data[0],
        };
      }
      this.pageNo = 1;
      let searchList = data;
      sessionStorage.setItem("searchData", JSON.stringify(searchList));
      this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
    },
    handleClick(tab, event) {
      console.log(this.increaseActive);
    },
    // 弹窗关闭
    handleClose() {
      this.reserves = [];
      this.reservesCopy = [];
      this.fixedTime = [];
      this.copyFixedTime = [];
      this.parameterCheck = true;
      this.otherCheck = true;
      this.modifyParametersShow = false;
      this.isSingleUpdate = false;
      this.dragSort = false;
      this.ModifyTemplateInvestmentShow = false;
      this.updateTemplateNameShow = false;
      this.terminationShow = false;
      // this.suspendShow = false;
      this.increaseShow = false;
      this.addCurrencyPairShow = false;
      this.detailPairShow = false;
      this.contrIncreaseShow = false;
      this.fixedInvestment = null;
    },
    // 添加账户
    addAccount() {
      console.log(this.currentTemplate.templateId);
      if (!this.detailsOperationAuthority) {
        // this.$message.error(this.$t('m.加')"不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限加'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$router.push({
        path: "addAccount",
        query: {
          templateId: this.currentTemplate.templateId,
          listIndex: this.listIndex,
          listDataNum: this.listDataNum,
        },
      });
    },
    // 创建策略模板
    createPolicyTemplate() {
      this.$emit("backBtn", "1-1");
    },
    // 操作系列
    operationBtn(val) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error(this.$t('m.加')"不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限加'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      console.log(this.selectList);
      let selectList = [];
      this.selectList.forEach((e) => {
        selectList.push(e.strategyName);
      });
      selectList = new Set(selectList);
      if (this.selectList.length !== 0 && selectList.size == 1) {
        let selectList = this.selectList;
        switch (val) {
          case "details": //详情
            this.$router.push({
              path: "strategyDetails",
              query: { executeId: selectList.executeId },
            });
            break;
          case "batchTermination": //批量终止
            this.terminationShow = true;
            break;
          case "suspend": //暂停
            this.confirmSuspend();
            // this.suspendShow = true;
            break;
          case "restart":
            this.confirmRestart(); //重启
            // this.suspendShow = true;
            break;
          case "increaseShow": //加仓
            this.getLimitConfig();
            this.increaseShow = true;
            break;
          case "message": //消息
            this.$router.push({
              path: "strategyMessage",
              query: { executeId: selectList.executeId },
            });
          case "add": //合约加仓
            this.contrIncreaseType = "add";
            this.contrIncreaseShow = true;
            break;
          case "subtract": //合约减仓
            this.contrIncreaseType = "subtract";
            this.contrIncreaseShow = true;
            break;

          default:
            break;
        }
      } else {
        if (this.selectList.length == 0) {
          return this.$message.warning("请选择实例");
        } else {
          return this.$message.warning("请选择同一种策略");
        }
      }
    },
    //修改参数
    batchUpdate() {
      this.getTemplateMessage("batch");
    },

    //删除子币对
    detailPair() {
      this.detailPairList = this.currentTemplate.pairs;
      this.detailPairShow = true;
      console.log(this.currentTemplate.pairs);
    },
    //增加子币对
    addCurrencyPair() {
      this.getPairPage();
    },
    // 查询策略模板列表
    getTemplateList() {
      if (!this.userId) {
        return;
      }
      let payload = {
        userId: this.userId,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getTemplateList(payload).then((res) => {
        if (res.status == 200) {
          this.templateShow = true;
          console.log("策略模板列表", res.data);
          if (res.data.templateList) {
            this.templateList = res.data.templateList;
            if (sessionStorage.getItem("currentTemplate")) {
              let currentTemplate = JSON.parse(
                sessionStorage.getItem("currentTemplate")
              );
              this.listIndex =
                currentTemplate.listIndex == 0
                  ? 0
                  : Number(currentTemplate.listIndex);
              this.listDataNum =
                currentTemplate.listDataNum == 0
                  ? 0
                  : Number(currentTemplate.listDataNum);
            }
            if (this.templateList.length <= 10) {
              this.listData = this.templateList;
            } else {
              let listData = [];
              for (
                let i = this.listDataNum * 10;
                i < (this.listDataNum + 1) * 10;
                i++
              ) {
                if (this.templateList[i]) {
                  listData.push(this.templateList[i]);
                }
              }
              this.listData = listData;
            }
            console.log(this.listIndex, this.listDataNum);

            this.currentTemplate = this.listData[this.listIndex];
            console.log(this.currentTemplate.templateName);
            this.investCoin = this.currentTemplate.investCoin;
            this.orderData.forEach((e) => {
              if (e.title.indexOf("总投入") >= 0) {
                e.title = "总投入(" + this.currentTemplate.investCoin + ")";
              } else if (e.title.indexOf("总收益") >= 0) {
                e.title = "总收益(" + this.currentTemplate.investCoin + ")";
              }
            });
            this.currentTemplate.listIndex = this.listIndex;
            this.currentTemplate.listDataNum = this.listDataNum;
            sessionStorage.setItem(
              "currentTemplate",
              JSON.stringify(this.currentTemplate)
            );
            //
            this.automatic =
              this.currentTemplate.tradeSwitch == 1 ? true : false;
            this.templateNameCopy = JSON.parse(
              JSON.stringify(this.currentTemplate.templateName)
            );
            this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
          } else {
            this.templateList = null;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询模板详情
    getStrategyPageByTemplateList(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: id,
        errorInfo: false,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      if (sessionStorage.getItem("searchData")) {
        let searchData = JSON.parse(sessionStorage.getItem("searchData"));
        payload.states =
          searchData[1] == "执行中"
            ? "EXECUTE"
            : searchData[1] == "暂停中"
            ? "SUSPEND"
            : searchData[1] == "终止中"
            ? "STOPING"
            : searchData[1] == "启动中"
            ? "STARTING"
            : searchData[1] == "启动错误"
            ? "STARTERROR"
            : "";
        payload.publicCode = searchData[0];
      } else {
        payload.states = this.searchTable.states;
        payload.publicCode = this.searchTable.publicCode;
      }

      console.log(this.searchTable);
      getStrategyPageByTemplateList(payload).then((res) => {
        if (res.status == 200) {
          console.log("策略模板详情", res.data);

          sessionStorage.setItem('strategyTemplateMap',JSON.stringify(res.data))



          // console.log(JSON.parse(res.data.strategyList[0].config))
          this.detailsList = res.data;
          if (res.data.strategyList) {
            let list = this.detailsList.strategyList;
            for (let item of list) {
              item.earnings = Number(item.earnings);
              item.realEarnings = Number(
                item.realEarnings.slice(0, item.realEarnings.indexOf(".") + 5)
              );
              if (item.states == "EXECUTE") {
                this.executeShow = true;
              } else if (item.states == "SUSPEND") {
                this.suspendDataShow = true;
              }
              item.investCoinNum = Number(item.investCoinNum);
              item.executeTime = this.date_format(
                Date.parse(new Date()) - item.startTime
              );
              item.code = item.strategyName + "(" + item.code + ")";
              item.startTime = this.timestampToTime(item.startTime);
              item.states =
                item.states == "EXECUTE"
                  ? "执行中"
                  : item.states == "SUSPEND"
                  ? "暂停中"
                  : item.states == "STOPING"
                  ? "终止中"
                  : item.states == "STARTING"
                  ? "启动中"
                  : item.states == "STARTERROR"
                  ? "启动错误"
                  : "";

              item.state = [];
              item.state.push(item.states);
              item.state.push(
                item.realEarnings > 0
                  ? "true5"
                  : item.realEarnings < 0
                  ? "false5"
                  : "zero5",
                Number(item.realYearIncome) > 0
                  ? "true6"
                  : Number(item.realYearIncome) < 0
                  ? "false6"
                  : "zero6"
              );
              item.realEarnings += "(" + Number(item.realEarningsRate) + "%)";
              item.realYearIncome =
                Number(item.realYearIncome) > 0
                  ? "+" + Number(item.realYearIncome) + "%"
                  : Number(item.realYearIncome) + "%";
            }
            this.tableData = JSON.parse(JSON.stringify(list));
            this.strategyListCopy = JSON.parse(JSON.stringify(list));

            this.listNumber = res.data.total;
            if (this.currentTemplate.templatePattern == 1) {
              this.pairs = JSON.parse(res.data.strategyList[0].config).pairs;
            }
          } else {
            this.tableData = [];
            this.listNumber = 0;
          }

          let currentTemplate = this.currentTemplate;
          this.transactionPair = currentTemplate.transactionPair;
          if (
            currentTemplate.exchangeName == "Binance" &&
            currentTemplate.transactionType != "spot"
          ) {
            this.transactionPair =
              currentTemplate.transactionPair.substring(
                0,
                currentTemplate.transactionPair.indexOf(
                  currentTemplate.investCoin
                )
              ) +
              "/" +
              currentTemplate.investCoin;
          }
          if (
            (currentTemplate.exchangeName == "OkexV5" ||
              currentTemplate.exchangeName == "Okex") &&
            currentTemplate.transactionType != "spot"
          ) {
            this.transactionPair =
              currentTemplate.transactionPair.split("-")[0] +
              "/" +
              currentTemplate.investCoin;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询账户列表
    getAvailableList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: this.templateId,
        errorInfo: false,
      };
      getAvailableList(payload).then((res) => {
        if (res.status == 200) {
          console.log("账户列表", res.data);
          let availableList = res.data.availableList;
          let list = [];
          availableList.forEach((e) => {
            if (e.available > e.investCoinNum) {
              list.push(e);
            }
          });
          this.availableList = list;
          // console.log(JSON.parse(res.data.investConfig));
          // this.investConfig = JSON.parse(res.data.investConfig);
          // this.templateList = res.data.templateList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳详情
    toLogDetail() {
      this.$router.push({
        path: "operationLog",
        query: {
          templateId: this.currentTemplate.templateId,
          projectActive: "1-2",
        },
      });
    },
    // 修改模板名字
    modifyName() {
      // updateTemplateNameShow
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        templateName: this.templateNameCopy,
      };
      updateTemplateName(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.修改成功'));
          this.getTemplateList();
          this.updateTemplateNameShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 自动交易开关
    updateTemplateTradeSwitch(type) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error(this.$t('m.加')"不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限加'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        this.automatic = !type;
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        tradeSwitch: type ? 1 : 0,
      };
      updateTemplateTradeSwitch(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.修改成功'));
          // this.updateTemplateNameShow = false;
          this.getTemplateList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 移除策略模板
    deleteStrategyTemplate(id) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error(this.$t('m.加')"不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限加'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$confirm("确认移除模板？")
        .then((_) => {
          let payload = {
            token: sessionStorage.getItem("token"),
            templateId: this.currentTemplate.templateId,
          };
          console.log(payload);
          // done();
          deleteStrategyTemplate(payload).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$message.success(this.$t('m.操作成功'));
              this.currentTemplate = null;
              this.listDataNum = 0;
              this.listIndex = 0;
              sessionStorage.removeItem("currentTemplate");
              this.getTemplateList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },

    // 复制交易
    copyTransaction() {
      console.log(1);

      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
      };

      getTemplateMessage(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          console.log(JSON.parse(res.data.config));
          console.log(JSON.parse(res.data.investConfig))
          let copyStrategy = {};

          copyStrategy.strategyName = res.data.strategyName;
          copyStrategy.strategyId = res.data.strategyId;
          copyStrategy.stopLoss = JSON.parse(res.data.config).stopLoss;
          copyStrategy.stopProfit = JSON.parse(res.data.config).stopProfit;
          copyStrategy.stopTime = JSON.parse(res.data.config).stopTime;
          copyStrategy.reserves = JSON.parse(res.data.config).reserves;



          copyStrategy.stopLoss=JSON.parse(res.data.config).stopLoss
          copyStrategy.stopProfit=JSON.parse(res.data.config).stopProfit
          copyStrategy.stopTime=JSON.parse(res.data.config).stopTime

          copyStrategy.investConfig=JSON.parse(res.data.investConfig)
          copyStrategy.templatePattern=res.data.templatePattern
          copyStrategy.tradePairs=JSON.parse(res.data.config).tradePairs[0]


          if(JSON.parse(res.data.config).pairs){
            copyStrategy.pairs=JSON.parse(res.data.config).pairs
          }

          console.log(copyStrategy)


          sessionStorage.setItem('copyStrategy',JSON.stringify(copyStrategy))
// return
          this.$emit("childFn", "1-1");

        } else {
          this.$message.error(res.msg); 
        }
      });
    },

    // 批量暂停
    confirmSuspend() {
      let executeIds = "";
      this.selectList.forEach((e, index) => {
        if (index == 0) {
          executeIds = e.executeId;
        } else {
          executeIds += "," + e.executeId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        executeIds: executeIds,
      };
      batchSuspend(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.暂停成功'));
          this.suspendDataShow = false;
          this.executeShow = false;
          this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 批量重启
    confirmRestart() {
      let executeIds = "";
      this.selectList.forEach((e, index) => {
        if (index == 0) {
          executeIds = e.executeId;
        } else {
          executeIds += "," + e.executeId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        executeIds: executeIds,
      };
      batchRestart(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.重启成功'));
          this.suspendDataShow = false;
          this.executeShow = false;
          this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 定投参数
    handleChange(value) {
      this.fixedTime = value;
    },

    // 兑U计算
    calculation(currency, pairMap, val) {
      if (!pairMap) {
        return;
      }
      var number = null;
      if (currency == pairMap.baseCoin) {
        number = val / (Number(pairMap.usdPrice) / Number(pairMap.last));
      } else {
        number = Number(val) / Number(pairMap.usdPrice);
      }
      return Math.floor(number * 1000000) / 1000000;
    },

    // 策略详情数据
    async getTemplateMessage(type) {
      //**************** */ 判断操作权限*************************

      if (!this.detailsOperationAuthority) {
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限加'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }

      //************************批量修改*********************

      if (type) {
        let payload = {
          token: sessionStorage.getItem("token"),
          templateId: this.currentTemplate.templateId,
        };
        await getTemplateMessage(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);

            let config = JSON.parse(res.data.config);
            console.log(config);
            config.reserves.forEach((e) => {
              e.value = Number(e.value);
            });
            this.config = config;

            this.strategyName = res.data.strategyName;
            this.strategyId = res.data.strategyId;
            if (
              this.strategyId != "WL202007311426363424x" &&
              this.strategyId != "WL202007311426363425x" &&
              this.strategyId != "WL20210624182847743"
            ) {
              this.transactionType == "spot";
            } else {
              this.transactionType == "contract_usdt";
            }
            console.log(config);
          }
        });
      } else {
        //************ */ 单个修改参数*********************

        this.isSingleUpdate = true;

        if (sessionStorage.getItem("selectStrategy")) {
          let selectStrategy = JSON.parse(
            sessionStorage.getItem("selectStrategy")
          );
          console.log(selectStrategy);
          console.log(JSON.parse(selectStrategy[0].config));
          this.selectStrategy = selectStrategy;
          sessionStorage.removeItem("selectStrategy");
          this.config = JSON.parse(selectStrategy[0].config);
          console.log(this.config);
          this.strategyName = selectStrategy[0].strategyName;
          this.strategyId = selectStrategy[0].strategyId;

          if (
            this.strategyId != "WL202007311426363424x" &&
            this.strategyId != "WL202007311426363425x" &&
            this.strategyId != "WL20210624182847743"
          ) {
            this.transactionType == "spot";
          } else {
            this.transactionType == "contract_usdt";
          }
        }
      }

      //***********NEXT***************/

      this.tradePairs = this.config.tradePairs[0];
      this.openingThreshold = this.tradePairs.openThreshold * 100;
      this.reserves = JSON.parse(JSON.stringify(this.config.reserves));
      this.reservesCopy = JSON.parse(JSON.stringify(this.config.reserves));
      this.contractType = this.tradePairs.investType;

      console.log(this.reserves);
      this.stopProfit = this.config.stopProfit
        ? this.config.stopProfit * 100
        : null;
      this.stopLoss = this.config.stopLoss
        ? Number(this.config.stopLoss) * -1 * 100
        : null;
      this.stopTime = this.config.stopTime
        ? Number(this.config.stopTime)
        : null;
      console.log(this.strategyId);
      if (
        this.strategyId == "WL20210512112808681" ||
        this.strategyId == "WL202X0512112808681"
      ) {
        for (let key of this.reserves) {
          if (key.name == "补仓金额模式") {
            this.replenishment = key.value == 0 ? true : false;
          } else if (key.name == "补仓间隔模式") {
            console.log(key);
            this.intervalMode = key.value == 0 ? true : false;
          }
        }
      }
      if (this.strategyId == "WL20210624182847743") {
        this.reserves.forEach((key) => {
          if (key.name == "补仓下单模式") {
            console.log(key);
            this.replenishmentbutionTwo = key.value == 0 ? true : false;
          } else if (key.name == "止盈平仓模式") {
            this.replenishmentbutionThree = key.value == 0 ? true : false;
          } else if (key.name == "止盈下单模式") {
            this.replenishmentbutionFour = key.value == 0 ? true : false;
          } else if (key.name == "补仓金额模式") {
            this.replenishmentbutionOne = key.value == 0 ? true : false;
          }
        });
      }

      if (this.strategyId == "WL20190402171936974x") {
        for (let key of this.reserves) {
          if (key.name == "定投类型") {
            this.fixedTime[0] = key.value;
            this.copyFixedTime[0] = key.value;
          } else if (key.name == "定投周期") {
            this.fixedTime[1] = key.value;
            this.copyFixedTime[1] = key.value;
          }
        }
      }

      if (this.strategyId == "WL202007311426363424x") {
        for (let key of this.reserves) {
          if (key.name == "网格间隔(等比)" && key.value != 0) {
            this.gridDistribution = true;
          } else if (key.name == "网格间隔(等差)" && key.value != 0) {
            this.gridDistribution = false;
          }
        }
      }
      if (this.ModifyTemplateInvestmentShow) {
        this.modifyParametersShow = false;
      } else {
        this.modifyParametersShow = true;
      }
    },

    // 参数校验
    parameterVerification(e, item) {
      let value = Number(item.value);
      let currency = this.tradePairs.investCoin;
      let pairMap = this.tradePairs;
      let min = null;
      let max = null;

      if (item.valueMin && item.valueMin != "0") {
        min = this.calculation(currency, pairMap, Number(item.valueMin));
      } else {
        min = Number(item.start);
      }

      if (item.valueMax && item.valueMax != "0") {
        max = this.calculation(currency, pairMap, Number(item.valueMax));
      } else {
        max = Number(item.end);
      }

      if (!value && value != 0) {
        this.$message.error(this.$t('m.参数不能为空'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
        return;
      } else if (
        min > value &&
        item.name != "网格间隔(等差)" &&
        item.name != "价格上限" &&
        item.name != "价格下限"
      ) {
        console.log(value, min, item);
        this.$message.error(this.$t('m.参数不能小于最小范围'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (
        max < value &&
        item.name != "网格间隔(等差)" &&
        item.name != "价格上限" &&
        item.name != "价格下限"
      ) {
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.target.style.border = "1px solid #F23A4C";

        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (item.name == "网格间隔(等差)" && item.value == 0) {
        this.$message.error(this.$t('m.网格间隔(等差)不能为0'));
        e.target.style.border = "1px solid #F23A4C";

        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
      } else if (item.name == "价格上限" && item.value < 0) {
        this.$message.error(this.$t('m.价格上限不能小于0'));

        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        e.target.style.border = "1px solid #F23A4C";
        return;
      } else if (item.name == "价格下限" && item.value < 0) {
        this.$message.error(this.$t('m.价格下限不能小于0'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        e.target.style.border = "1px solid #F23A4C";
        return;
      } else {
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf(item.name),
            1
          );
        }
        console.log(this.parameterCheckList);
        e.target.style.border = "1px solid #C0C4CC";
      }
    },

    // 参数二次校验
    secondaryVerification() {
      let reserves = this.reserves;
      console.log(reserves);
      let isOk = null;
      for (let key of reserves) {
        if (key.name == "单格投入" && key.value == 0) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          // this.$message.error(this.$t('m.加')"单格投入不正确，请检查修改");
          return (isOk = false);
        } else if (
          key.name == "首单投入" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          // this.$message.error(this.$t('m.加')"首单投入不正确，请检查修改");
          return (isOk = false);
        } else if (
          key.name == "首单金额" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          // this.$message.error(this.$t('m.加')"首单金额不正确，请检查修改");
          return (isOk = false);
        } else if (
          key.name == "单笔金额" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          // this.$message.error(this.$t('m.加')"单笔金额不正确，请检查修改");
          return (isOk = false);
        } else if (
          key.name == "买入价格" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          // this.$message.error(this.$t('m.加')"买入价格不正确，请检查修改");
          return (isOk = false);
        } else if (
          key.name == "卖出价格" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          // this.$message.error(this.$t('m.加')"卖出价格不正确，请检查修改");
          return (isOk = false);
        } else if (
          key.name == "网格间隔(等差)" &&
          key.value == 0 &&
          !this.gridDistribution
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          // this.$message.error(this.$t('m.加')"网格间隔(等差)不能为0");
          return (isOk = false);
        } else if (
          key.name == "网格间隔(等比)" &&
          (Number(key.value) < 0.1 || Number(key.value) > 50) &&
          this.gridDistribution
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          // this.$message.error(this.$t('m.加')"网格间隔(等比)不能为0");
          return (isOk = false);
        } else {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            this.parameterCheckList.splice(
              this.parameterCheckList.indexOf(key.name),
              1
            );
          }
          isOk = true;
        }
      }
      console.log(this.parameterCheck);
      return isOk;
    },

    // 止盈/止损校验
    otherSettingCheck(type, v) {
      console.log(type);
      if (type == "止盈" && Number(v) < 0) {
        this.$message.warning("止盈比例不能小于0");
        this.otherCheck = false;
      } else if (type == "止损" && Number(v) < 0) {
        this.$message.warning("止损比例不能小于0");
        this.otherCheck = false;
      } else if (type == "止损" && Number(v) > 100) {
        this.$message.warning("止损比例不能大于100");
        this.otherCheck = false;
      } else {
        this.otherCheck = true;
      }
    },
    // 策略阈值校验
    openingThresholdChange(v) {
      console.log(v);
      if (v < 0) {
        this.$message.error(this.$t('m.策略开仓阈值不能小于0'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (!v) {
        this.$message.error(this.$t('m.策略开仓阈值不能为空'));

        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
      } else {
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf(item.name),
            1
          );
        }
      }
    },

    // 修改参数
    confirmUpdateParam() {
      if (this.parameterCheckList.length !== 0) {
        return this.$message.error(this.$t('m.参数配置不正确，请重新修改'));
      }
      this.secondaryVerification();

      if (this.parameterCheckList.length !== 0) {
        this.$message.error(this.$t('m.参数配置不正确，请重新修改'));
        return;
      }
      if (!this.otherCheck) {
        this.$message.error(this.$t('m.其他设置参数不正确，请重新修改'));
        return;
      }

      if (this.strategyId == "WL202X0512112808681") {
        for (let key of this.reserves) {
          if (
            (key.id == "reserve_52" ||
              key.id == "reserve_53" ||
              key.id == "reserve_54") &&
            !key.signalValue
          ) {
            this.$message.error(key.name + "阈值不能为空");
            return;
          }
          // else if (
          //   (key.id == "reserve_52" ||
          //     key.id == "reserve_53" ||
          //     key.id == "reserve_54") &&
          //   Number(key.signalValue) <= 0
          // ) {
          //   this.$message.error(key.name + "阈值不能小于0");
          //   return;
          // }
          if (
            key.id == "reserve_52" ||
            key.id == "reserve_53" ||
            key.id == "reserve_54"
          ) {
            let item = {};
            item.signalId = key.signal.signalId;
            item.value = Number(key.signalValue);
            item.attrName = key.paramBodyValue;
            switch (key.ops) {
              case "=":
                item.opt = "eq";
                break;
              case ">":
                item.opt = "gt";
                break;
              case "<":
                item.opt = "lt";
                break;
              case "≦":
                item.opt = "le";
                break;
              case "≥":
                item.opt = "ge";
                break;
              default:
                break;
            }
            console.log(item);
            key.value = JSON.stringify(item);

            // "value": "{\"opt\":\"eq\",\"signalId\":\"d8aa6c8b8b874e31bd201e9f5510ac43\",\"value\":\"2\",\"attrName\":\"long\"}"
          }
        }
        console.log(this.reserves);
      }

      if (this.strategyId == "WL20190402171936974x") {
        for (let key of this.reserves) {
          if (key.name == "定投类型") {
            key.value = this.fixedTime[0];
          } else if (key.name == "定投周期") {
            key.value = this.fixedTime[1];
          }
        }
        console.log(this.reserves);
      }
      if (
        this.strategyId == "WL20210512112808681" ||
        this.strategyId == "WL202X0512112808681"
      ) {
        for (let key of this.reserves) {
          if (key.name == "补仓金额模式") {
            key.value = this.replenishment ? 0 : 1;
          } else if (key.name == "补仓间隔模式") {
            console.log(key);
            key.value = this.intervalMode ? 0 : 1;
          }
        }
      }
      if (this.strategyId == "WL20210624182847743") {
        for (let key of this.reserves) {
          if (key.name == "补仓下单模式") {
            console.log(key);
            key.value = this.replenishmentbutionTwo ? 0 : 1;
          } else if (key.name == "止盈平仓模式") {
            key.value = this.replenishmentbutionThree ? 0 : 1;
          } else if (key.name == "止盈下单模式") {
            key.value = this.replenishmentbutionFour ? 0 : 1;
          } else if (key.name == "补仓金额模式") {
            key.value = this.replenishmentbutionOne ? 0 : 1;
          }
        }
      }

      console.log(this.reserves);

      //************* */ 单个修改****************

      if (this.isSingleUpdate) {
        let payload = {
          token: sessionStorage.getItem("token"),
          reserves: JSON.stringify(this.reserves),
          executeId: this.selectStrategy[0].executeId,
          stopProfit: this.stopProfit ? Number(this.stopProfit) / 100 + "" : "",
          stopLoss: this.stopLoss ? (this.stopLoss / 100) * -1 + "" : "",
          stopTime: this.stopTime ? new Date(this.stopTime).getTime() : "",
        };

        if (
          this.strategyId == "WL202007311426363425x" ||
          this.strategyId == "WL202007311426363424x" ||
          this.strategyId == "WL20210624182847743"
        ) {
          payload.openThreshold = this.openingThreshold / 100;
          contractUpdateParam(payload).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$message.success(this.$t('m.修改参数成功'));
              this.modifyParametersShow = false;
              this.isSingleUpdate = false;
              this.getStrategyPageByTemplateList(
                this.currentTemplate.templateId
              );
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log(payload);
          updateParam(payload).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$message.success(this.$t('m.修改参数成功'));
              this.modifyParametersShow = false;
              this.isSingleUpdate = false;
              this.getStrategyPageByTemplateList(
                this.currentTemplate.templateId
              );
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      } else {
        // **********************批量修改******************

        let list = JSON.parse(JSON.stringify(this.selectList));
        console.log(list);
        let executeIds = [];
        if (list.length > 0) {
          for (let key of list) {
            executeIds.push(key.executeId);
          }
        }
        console.log(this.reserves);

        let payload = {
          token: sessionStorage.getItem("token"),
          reserves: JSON.stringify(this.reserves),
          templateId: this.currentTemplate.templateId,
          executeIds: executeIds.join(","),
          stopProfit: this.stopProfit ? this.stopProfit / 100 + "" : "",
          stopLoss: this.stopLoss ? (this.stopLoss / 100) * -1 + "" : "",
          stopTime: this.stopTime ? new Date(this.stopTime).getTime() : "",
        };
        if (
          this.strategyId == "WL202007311426363425x" ||
          this.strategyId == "WL202007311426363424x" ||
          this.strategyId == "WL20210624182847743"
        ) {
          payload.openThreshold = this.openingThreshold / 100;
        }
        console.log(payload);

        batchUpdateParam(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.修改参数成功'));
            this.modifyParametersShow = false;
            this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    // 批量终止
    batchStrategyEnd() {
      let executeIds = "";
      this.selectList.forEach((e, index) => {
        if (index == 0) {
          executeIds = e.executeId;
        } else {
          executeIds += "," + e.executeId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        executeIds: executeIds,
      };
      batchStrategyEnd(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.终止成功'));
          this.terminationShow = false;
          this.getTemplateList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询加仓范围
    getLimitConfig() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      getLimitConfig(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.limitMap = res.data.limitMap;
          this.min = JSON.parse(JSON.stringify(this.limitMap.minInvest));
          this.max = JSON.parse(JSON.stringify(this.limitMap.maxInvest));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 加仓
    scaleIn() {
      let executeIds = "";
      this.selectList.forEach((e, index) => {
        if (index == 0) {
          executeIds = e.executeId;
        } else {
          executeIds += "," + e.executeId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        executeIds: executeIds,
      };
      if (this.increaseActive == "1") {
        if (!this.proportion || this.proportion == "") {
          this.$message.error(this.$t('m.投入比例不能为空'));
          return;
        } else if (this.proportion <= 0) {
          this.$message.error(this.$t('m.投入比例不能小于等于0%'));
          return;
        } else if (this.proportion > 100) {
          this.$message.error(this.$t('m.投入比例不能大于100%'));
          return;
        } else if (this.min < Number(this.limitMap.minInvest)) {
          this.$message.error(this.$t('m.加仓投入下限不能小于最小投入'));
          return;
        } else if (this.max > Number(this.limitMap.maxInvest)) {
          this.$message.error(this.$t('m.加仓投入上限限不能大于最大投入'));
          return;
        } else if (this.min > Number(this.max)) {
          this.$message.error(this.$t('m.加仓投入下限不能大于加仓投入上限'));
          return;
        }
        let investConfig = {
          proportionInvest: {
            proportion: this.proportion,
            maxInvest: this.max,
            minInvest: this.min,
          },
        };
        payload.investConfig = { ...investConfig };
      } else {
        if (!this.fixedInvestment || this.fixedInvestment == "") {
          this.$message.error(this.$t('m.投入金额不能为空'));
          return;
        } else if (this.fixedInvestment < 0) {
          this.$message.error(this.$t('m.投入金额不能小于0'));
          return;
        }
        let investConfig = {
          fixInvest: this.fixedInvestment,
          investCoin: this.currentTemplate.investCoin,
        };
        payload.investConfig = { ...investConfig };
      }
      console.log(payload);

      batchAddStore(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.操作成功'));
          this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
          if (this.increaseActive == "1") {
            this.proportion = null;
            this.max = null;
            this.min = null;
          } else {
            this.fixedInvestment = null;
          }
          this.increaseShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询交易对
    getPairPage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        exchangeId: this.currentTemplate.exchangeId,
      };
      getPairPageById(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let list = res.data.pairList;
          let coinList = res.data.coinList;
          let pair = this.currentTemplate.pairs;
          let newList = [];
          if (pair.length !== 0) {
            list.forEach((e) => {
              coinList.forEach((item) => {
                if (item == e.counterCoin && e.baseCoin == "USDT") {
                  newList.push(e);
                }
              });
            });
            console.log(newList);
            let newDataList = newList;
            pair.forEach((e) => {
              newList.forEach((item, index) => {
                if (e.counterCoin == item.counterCoin) {
                  console.log(item, e);
                  console.log(newDataList);
                  newDataList.splice(index, 1);
                }
              });
            });
            // for (let i = 0; i < list.length; i++) {
            //   for (let j = 0; j < pair.length; j++) {
            //     if (
            //       list[i].baseCoin == pair[j].baseCoin &&
            //       list[i].counterCoin == pair[j].counterCoin
            //     ) {
            //       list[i].type = true;
            //     }
            //   }
            // }
            // let newList = list.filter(function (item) {
            //   return item.type != true;
            // });
            this.pairList = newDataList;
          } else {
            this.pairList = list;
          }

          this.addCurrencyPairShow = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 币对选中
    pairChange(item) {
      if (!item.type) {
        item.type = true;
      } else {
        item.type = false;
      }
      console.log(item);
    },
    // 增加子币对
    confirmAddCurrencyPair() {
      let list = this.pairList;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type == true) {
          let pair = {};
          pair.baseCoin = list[i].baseCoin;
          pair.counterCoin = list[i].counterCoin;
          pairs.push(pair);
        }
      }
      let executeIds = "";
      console.log(this.selectList);
      this.selectList.forEach((e, index) => {
        if (index == 0) {
          executeIds = e.executeId;
        } else {
          executeIds += "," + e.executeId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: this.currentTemplate.templateId,
        pairs: JSON.stringify(pairs),
        executeIds: executeIds,
      };

      batchManyAddSymbol(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.增加币对成功'));
          this.getTemplateList();
          this.addCurrencyPairShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改模板投入范围
    ModifyTemplate() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error(this.$t('m.加')"不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限加'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.ModifyTemplateInvestmentShow = true;

      this.getTemplateMessage("batch");
      this.getLimitConfig();
      let config = JSON.parse(this.currentTemplate.investConfig);
      this.investConfig = config;
      console.log(this.investConfig);
    },
    //确定修改
    confirmModifyTemplate() {
      console.log(this.investConfig, this.templateActive);
      if (this.templateActive == "1") {
        this.investConfig.fixInvest = null;
        let proportion = this.investConfig.proportionInvest.proportion;
        let min = this.investConfig.proportionInvest.minInvest;
        let max = this.investConfig.proportionInvest.maxInvest;
        if (proportion == null || proportion == "") {
          this.$message.error(this.$t('m.请输入投入比例'));
          return;
        } else if (proportion <= 0) {
          this.$message.error(this.$t('m.投入比例不能小于等于0%'));
          return;
        } else if (proportion > 100) {
          this.$message.error(this.$t('m.投入比例不能大于100%'));
          return;
        } else if (min < Number(this.limitMap.minInvest)) {
          this.$message.error(this.$t('m.加仓投入下限不能小于最小投入'));
          return;
        } else if (max > Number(this.limitMap.maxInvest)) {
          this.$message.error(this.$t('m.加仓投入上限限不能大于最大投入'));
          return;
        } else if (min > Number(max)) {
          this.$message.error(this.$t('m.加仓投入下限不能大于加仓投入上限'));
          return;
        }
      } else {
        this.investConfig.proportionInvest.proportion = null;
        let fixInvest = this.investConfig.fixInvest;
        if (fixInvest == null) {
          this.$message.error(this.$t('m.请输入投入金额'));
          return;
        } 

        //测试代码
        else if (fixInvest < Number(this.limitMap.minInvest)) {
          this.$message.error(this.$t('m.投入金额不能小于最小投入'));
          return;
        } else if (fixInvest > Number(this.limitMap.maxInvest)) {
          this.$message.error(this.$t('m.投入金额不能大于最大投入'));
          return;
        }
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        investConfig: this.investConfig,
      };

      updateTemplateInvestCoinnNum(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.修改成功'));
          this.ModifyTemplateInvestmentShow = false;
          this.getTemplateList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 策略状态筛选
    typeSelest(v) {
      console.log(v);
      let list = JSON.parse(JSON.stringify(this.strategyListCopy));
      let newList = [];
      switch (v) {
        case "全部":
          this.strategyList = JSON.parse(JSON.stringify(this.strategyListCopy));
          break;
        case "正常状态":
          console.log(2);
          for (let i = 0; i < list.length; i++) {
            if (list[i].errorInfo == false) {
              newList.push(list[i]);
            }
          }
          this.strategyList = newList;
          break;
        case "异常状态":
          console.log(3);
          for (let i = 0; i < list.length; i++) {
            if (list[i].errorInfo == true) {
              newList.push(list[i]);
            }
          }
          this.strategyList = newList;
          break;
        default:
          break;
      }
      console.log(this.strategyList);
    },
    // 删除币对
    confirmDetailPair() {
      let list = this.detailPairList;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type) {
          let item = {};
          item.baseCoin = list[i].baseCoin;
          item.counterCoin = list[i].counterCoin;
          pairs.push(item);
        }
      }
      if (pairs.length == 0) {
        this.$message.error(this.$t('m.请选择交易对'));
        return;
      } else if (pairs.length == this.detailPairList.length) {
        this.$message.error(this.$t('m.至少保留一个交易对'));
        return;
      }
      let executeIds = "";
      console.log(this.selectList);
      this.selectList.forEach((e, index) => {
        if (index == 0) {
          executeIds = e.executeId;
        } else {
          executeIds += "," + e.executeId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        pairs: JSON.stringify(pairs),
        executeIds: executeIds,
      };
      deletePair(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.操作成功'));
          this.detailPairShow = false;
          this.getTemplateList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取排序模板
    getTemplateSortList() {
      let payload = {
        userId: this.userId,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getTemplateSortList(payload).then((res) => {
        if (res.status == 200) {
          console.log("排序模板列表", res.data);
          let sortTemplateList = res.data.templateList;
          for (let key of sortTemplateList) {
            key.tradeSwitch = JSON.parse(key.tradeSwitch);
          }
          this.sortTemplateList = sortTemplateList;
          console.log(this.sortTemplateList);
          this.dragSort = true;
        }
      });
    },
    // 修改模板排序
    sortConfirm() {
      let list = this.sortTemplateList;
      let payload = {
        token: sessionStorage.getItem("token"),
        templateSortList: [],
      };
      for (var i = 0; i < list.length; i++) {
        let obj = {};
        obj.templateId = list[i].templateId;
        obj.tradeSwitch = (list[i].tradeSwitch ? 1 : 0) + "";
        obj.sort = i + 1;
        payload.templateSortList.push(obj);
      }

      console.log(this.sortTemplateList);
      console.log(payload);
      updateTemplateSort(payload).then((res) => {
        if (res.status == 200) {
          this.dragSort = false;
          this.currentTemplate = null;
          this.listDataNum = 0;
          this.getTemplateList();
          this.listIndex = 0;
          // this.getStrategyPageByTemplateList(this.currentTemplate.templateId)
          console.log(res.data);
        }
      });
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
      console.log(`每页 ${val} 条`);
    },
    // 分页按钮
    handleCurrentChange(val) {
      this.pageNo = val;
      sessionStorage.setItem("pageNo", val);
      this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
      console.log(`当前页: ${val}`);
    },
    // 日记按钮
    getOperateLog() {
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        pageNo: 1,
        pageSize: 3,
      };
      getOperateLog(payload).then((res) => {
        if (res.status == 200) {
          console.log("日志", res.data);
          this.logError = res.data.isException;
          let logList = res.data.logList;
          logList.forEach((e) => {
            e.createTime = this.timestampToTime(e.createTime);
          });
          this.logList = logList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //日志
    openLog() {
      this.showIcon = true;
      this.getOperateLog();
    },
    backLog() {
      this.showIcon = false;
    },
    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //时分秒换算
    date_format(micro_second) {
      var second = Math.floor(micro_second / 1000);
      var day = Math.floor(second / 3600 / 24);
      var hr = Math.floor((second / 3600) % 24);
      var min = Math.floor((second / 60) % 60);
      return day + "d " + hr + "h " + min + "min";
    },

    // // 合约加减弹窗
    // contractPosition(v){
    //   console.log(v)
    //   this.contrIncreaseType=v
    // },
    // 取消加减仓
    contrIncreaseCancel() {
      this.fixedInvestment = null;
      this.contrIncreaseShow = false;
    },
    // 确定加减仓
    contrIncreaseConfirm() {
      console.log(this.contrIncreaseType, this.fixedInvestment);

      if (!this.fixedInvestment || this.fixedInvestment == "") {
        this.$message.error(this.$t('m.投入金额不能为空'));
        return;
      } else if (this.fixedInvestment < 0) {
        this.$message.error(this.$t('m.投入金额不能小于0'));
        return;
      }

      let executeIds = "";
      this.selectList.forEach((e, index) => {
        if (index == 0) {
          executeIds = e.executeId;
        } else {
          executeIds += "," + e.executeId;
        }
      });

      let payload = {
        token: sessionStorage.getItem("token"),
        type: this.contrIncreaseType,
        amount: this.fixedInvestment,
        executeIds: executeIds,
      };
      console.log(payload);

      batchPositionAdjustment(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.操作成功'));
          this.fixedInvestment = null;
          this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
          this.contrIncreaseShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //SMT合约
    replenishmentbutionSelect(v, item) {
      if (item == "一次性止盈" || item == "逐单止盈") {
        this.replenishmentbutionThree = !this.replenishmentbutionThree;
      }
      if (v) {
        for (let e of this.reserves) {
          if (item == "倍数补仓") {
            if (e.name == "补仓金额增量") {
              e.visiable = 0;
            }
            if (e.name == "补仓金额倍数" || e.name == "倍投起始单") {
              e.visiable = 1;
            }
            this.replenishmentbutionOne = true;
          } else if (item == "追踪补仓") {
            if (e.name == "补仓反弹比例") {
              e.visiable = 1;
            }
            this.replenishmentbutionTwo = true;
          } else if (item == "追踪止盈") {
            if (e.name == "止盈回撤比例") {
              e.visiable = 1;
            }
            this.replenishmentbutionFour = true;
          }
        }
      } else {
        for (let e of this.reserves) {
          if (item == "增量补仓") {
            if (e.name == "补仓金额增量") {
              e.visiable = 1;
            }
            if (e.name == "补仓金额倍数" || e.name == "倍投起始单") {
              e.visiable = 0;
            }

            this.replenishmentbutionOne = false;
          } else if (item == "预埋单补仓") {
            if (e.name == "补仓反弹比例") {
              e.visiable = 0;
            }
            this.replenishmentbutionTwo = false;
          } else if (item == "预埋单止盈") {
            if (e.name == "止盈回撤比例") {
              e.visiable = 0;
            }
            this.replenishmentbutionFour = false;
          }
        }
      }
    },
  },
  created() {
    sessionStorage.removeItem("Now");
    if (sessionStorage.getItem("searchData")) {
      this.searchTable = JSON.parse(sessionStorage.getItem("searchData"));
      console.log(this.searchTable);
    }
    if (sessionStorage.getItem("pageNo")) {
      this.pageNo = JSON.parse(sessionStorage.getItem("pageNo"));
    }
    this.$dragging.$on("dragged", (value) => {
      //排序的时候不需要做处理，所以空着就行
      console.log(value);
      console.log(this.aa);
    });

    let project = sessionStorage.getItem("project");
    let account = JSON.parse(sessionStorage.getItem("account"));
    this.project = JSON.parse(project);
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
    console.log(this.detailsOperationAuthority);
    let userId = this.$route.query.userId;
    if (userId) {
      this.userId = userId;
      this.getTemplateList();
    } else {
      this.userId = sessionStorage.getItem("userId");
      this.detailsOperationAuthority =
        this.userId == account.userId ? true : false;
      this.userId = account.userId;
      this.getTemplateList();
    }
  },
  mounted() {
    this.querySignalList();
  },
};
</script>




<style lang='scss' scoped>
.box-nav {
  width: 100%;
  height: 100%;
  position: relative;
  .navs {
    width: 1640px;
    height: calc(100% - 100px);
    padding: 20px;
    background: #fff;
    color: #55698a;
    overflow-y: hidden;
    .navStyle {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e6e9ee;
      padding: 10px 0;
      height: 30px;
      .navTop-left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        .navTopItem {
          border: 1px solid #e6e9ee;
          margin-right: 10px;
          width: 130px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-bottom: 0;
          cursor: pointer;
          border-radius: 3px 3px 0px 0px;
        }
        .ItemStyle {
          background: #2174ff;
          color: #fff;
        }
      }
      .navTop-right {
        margin-right: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .navCenterLeft {
        display: flex;
        align-items: center;
        div {
          margin-right: 20px;
        }
        .addBtn {
          width: 120px;
          height: 32px;
          border: 1px solid #2174ff;
          border-radius: 4px;
          color: #2174ff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          i {
            margin-top: 1px;
            background-color: #2174ff;
            color: white;
            border-radius: 50%;
            margin-right: 3px;
          }
        }
      }
      .navCenterRight {
        display: flex;
        align-items: center;
        div {
          margin-left: 20px;
          display: flex;
          font-size: 12px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .brdStyle {
          border-right: 2px solid #55698a;
          margin: 0 15px;
          height: 16px;
          line-height: 36px;
        }
      }
    }
    .searchStyle {
      width: 1600px;
      background-color: #32333d;
      color: #ffffff;
      padding: 0 20px;
    }
    .detailsData {
      height: 500px;
    }
    .paginStyle {
      display: flex;
      color: #55698a;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px;
      .paginCenter {
        display: flex;
        align-items: center;
      }
    }
  }
  .content {
    position: absolute;
    bottom: 0;
    width: 1680px;
    z-index: 1;
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 50px;
      border-radius: 8px;
      padding: 0 20px;
      background: #ffffff;
    }

    .selectBtn {
      padding: 0 20px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #e6e9ee;
      .bottom2 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 50px;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #e6e9ee;
      }
      .navSelect {
        display: flex;
        flex-direction: column;
        .navStyle {
          margin: 10px 0;
          display: flex;
          align-items: center;
          div {
            margin-right: 10px;
          }
        }
      }
    }
    .bottom-text {
      color: #102859;
      height: 100%;
      line-height: 50px;
    }
    .bottom-icon {
      width: 60px;
      height: 12px;
      background: #e6e9ee;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-radius: 0 0 8px 8px;
      cursor: pointer;
      .bottom-img {
        width: 8px;
        height: 4px;
      }
    }
    .more-btn {
      color: #758db5;
      height: 100%;
      line-height: 50px;
      cursor: pointer;
    }
  }

  // 缺省页
  .defaultPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #313237;
    font-size: 14px;
    background: #fff;
    border-radius: 8px;
    .img1 {
      width: 228px;
      height: 160px;
      margin-bottom: 10px;
    }
    .create-template {
      color: #007aff;
      cursor: pointer;
    }
    .img2 {
      width: 100%;
      height: 237px;
      margin-top: 60px;
    }
  }
  // 终止/暂停弹窗
  .termination,
  .suspend {
    font-size: 12px;
    margin: 0 24px;
    color: #333;
    line-height: 20px;
    .title {
      font-size: 14px;
      text-align: center;
      font-weight: 600;
    }
    .text {
      font-size: 12px;
      margin: 20px 0;
    }
    .tips {
      color: #999;
    }
  }
  // 加仓
  .increase {
    .title {
      font-size: 14px;
      color: #333;
      :last-child {
        font-size: 12px;
        color: #999;
        margin-left: 5px;
      }
    }
    .text {
      .box {
        margin-bottom: 10px;
        overflow-y: hidden;
        .box-title {
          display: inline-block;
          font-size: 10px;
          color: #666;
          margin-bottom: 4px;
        }
        .el-input--suffix {
          line-height: 40px;
        }
      }
    }
    .tips {
      font-size: 12px;
      color: #999;
      line-height: 18px;
      margin-top: 10px;
    }
  }
  // 修改参数
  .modifyParameters {
    height: 500px;
    overflow: scroll;
    padding-right: 20px;
    padding-bottom: 20px;
    // .box {
    //   margin-bottom: 10px;
    //   .box-title {
    //     display: inline-block;
    //     font-size: 14px;
    //     color: #333;
    //     margin-bottom: 4px;
    //     :last-child {
    //       font-size: 12px;
    //       color: #666;
    //       margin-left: 4px;
    //     }
    //   }
    //   ::v-deep.el-cascader {
    //     width: 100%;
    //   }
    // }
    .parameter-box {
      margin-bottom: 10px;
      .left {
        margin-bottom: 10px;
        .jieshi-tips {
          width: 12px;
          height: 12px;
          cursor: pointer;
          margin: 0 6px;
        }
      }
      .right {
        .el-input__suffix,
        .el-input--suffix {
          line-height: 40px;
        }
        .el-select {
          width: 100%;
        }
        .signal-params {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          .one {
            width: 35%;
          }
          .two {
            width: 30%;
          }
          .three {
            width: 30%;
          }
        }
      }
    }
    //SMT
    .parameter-box-smt {
      display: flex;
      // align-items: center;
      padding: 0 0 20px 0;
      .left {
        display: flex;
        flex-direction: column;
        width: 120px;
        .reserves-name {
          display: flex;
          align-items: center;
          color: #102859 !important;
          font-size: 14px !important;
          margin-bottom: 4px;
          img {
            width: 12px;
            height: 12px;
            cursor: pointer;
            margin-left: 10px;
          }
        }
        :last-child {
          color: #55698a;
          font-size: 12px;
        }
      }
      .right {
        flex: 1;
        .el-input {
          height: 40px;
        }
        .el-cascader {
          width: 100%;
        }
        ::v-deep.el-input__inner {
          height: 40px;
          line-height: 40px;
        }
        ::v-deep.el-input__suffix {
          line-height: 40px;
        }
        .el-select {
          width: 100%;
        }
        .signal-params {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          .one {
            width: 45%;
          }
          .two {
            width: 20%;
            /deep/.el-icon-arrow-up:before {
              content: "";
            }
            /deep/.el-input__inner {
              display: flex;
              align-items: center;
              padding-right: 10px;
            }
          }
          .three {
            width: 35%;
            /deep/.el-input__inner {
              padding-right: 10px;
            }
          }
        }
      }
      // 海风特殊参数样式
      .special-box {
        width: 100%;
        .special-box-title {
          color: #102859;
          font-size: 16px;
        }
        .special-box-cont {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          margin-top: 10px;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 40px;
            background: #f7f7f7;
            color: #102859;
            cursor: pointer;
            border-radius: 4px;
          }
          .on {
            background: #2174ff;
            color: #fff;
          }
        }
      }
      .special-box-cont {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 10px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 40px;
          background: #f7f7f7;
          color: #102859;
          cursor: pointer;
          border-radius: 4px;
        }
        .on {
          background: #2174ff;
          color: #fff;
        }
      }
    }
    .otherSetting-title {
      color: #2174ff;
      margin-bottom: 10px;
    }
    // 海风特殊参数样式
    .special-box {
      width: 100%;
      .special-box-title {
        color: #102859;
      }
      .special-box-cont {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 10px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 40px;
          background: #f7f7f7;
          color: #102859;
          cursor: pointer;
          border-radius: 4px;
        }
        .on {
          background: #2174ff;
          color: #fff;
        }
      }
    }
    .otherSetting {
      .parameter-box {
        .title {
          margin-bottom: 10px;
          .jieshi-tips {
            width: 12px;
            height: 12px;
            cursor: pointer;
            margin: 0 6px;
          }
        }
      }
    }
    .el-date-editor {
      width: 100%;
    }
  }
  // 修改参数确认
  .btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007aff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  // 增加币对
  .addCurrencyPair {
    height: 400px;
    overflow: scroll;
    .title {
      font-size: 12px;
      color: #999;
      margin-bottom: 10px;
    }
    .box {
      .checkbox-warp {
        margin-bottom: 10px;
        padding: 5px;
        .box-text {
          font-size: 14px;
          color: #666;
          :last-child {
            font-size: 12px;
            color: #999;
          }
        }
      }
    }

    .checkbox-warp:hover {
      background: #d5d5d5;
      border-radius: 4px;
    }
  }
  //模板排序
  .templateSort {
    padding: 0 20px 20px 20px;
    // ::v-deep.el-dialog__body{
    //   padding-top:10px !important;
    // }
    .title {
      text-align: center;
      margin-bottom: 30px;
      color: #8f9096;
    }
    .templateSort-content {
      .content-title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #dcdde3;
        color: #8f9096;
        :first-child {
          width: 50%;
        }
      }
      .content-box {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        color: #313237;
        cursor: pointer;
        :first-child {
          width: 50%;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>